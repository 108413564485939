<template>
    <div style="margin: 20;">
        
            <br>
            <v-row class="s-col-form">       
                <v-col class="s-col-form">
                    <s-select                        
                        label="Periodo de Acondicionado"
                        :items="ConditioningPeriods"
                        item-value="PchID"
                        item-text="TypePeriodoName"
                        return-object
                        v-model="selectedPeriod"
                        @input="ClickTypePeriodoName()"
                        >
                    </s-select>
                </v-col>         
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">	
                    <s-select-definition
                        label="Tipo corte Acondicionado"
                        :def="1463"
                        v-model="item.TypeCut"
                        disabled
                    ></s-select-definition>
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">	
                    <s-select
                        label="Tipo corte Empaque"
                        :items="TypesCutPackings"
                        item-value="TcpID"
                        item-text="TcpDescription"
                        v-model="item.TcpID"
                    ></s-select>
                </v-col>
                <v-col hidden cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select-definition
                        label="Estado"
                        v-model="item.PipStatus"
                        :def="1154"
                    ></s-select-definition>
                </v-col>
                
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select-definition
                        label="Tipo Empaque"
                        v-model="item.TypePacking"
                        :def="1435"
                    ></s-select-definition>
                </v-col>
                <!-- <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select-definition
                        label="Cultivo"
                        :def="1173"
                        v-model="item.TypeCultive"
                    ></s-select-definition>
                </v-col> -->
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select-definition
                        label="Tipo Cultivo"
                        disabled
                        :def="1172"
                        v-model="item.TypeCrop"
                    ></s-select-definition>
                </v-col>
                
            
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select-definition
                        label="Periodo"
                        v-model="item.TypePeriod"
                        :def="1444"
                    ></s-select-definition>
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select-client
                        clearable
                        label="Cliente"
                        v-model="item.CumID"
                        ref="txtCumID"
                        full>
                    </s-select-client>
                </v-col>
                 <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form" > 
                    <s-select-brands                                                
                        clearable
                        label="Marcas"
                        v-model="item.CbdID"
                        ref="SelectBrands"
                        :cumID="item.CumID"
                        full>
                    </s-select-brands>                                    
                </v-col>  
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <label  aria-hidden="true" class="v-label theme--light"><b>Hora de Inicio de Periodo</b></label>
                    <div class="relojito">
                        <datetime valueZone="America/Lima"  type="time" v-model="PprHour">
                        </datetime>
					</div>
                </v-col>

                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select-definition v-model="item.TypeProduction" 
                        label="T. Producción"
						:def="1464" 
                        @input="changeTypeProduction($event)">
                    </s-select-definition>
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select v-model="item.TypeVariant" 
                        label="Variante"
                        :items="ListVariante"
                        item-value="TsfID"
                        item-text="TsfDescription"
					></s-select>
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select v-model="item.StateFinal"
                        label="Estado Final"
                        item-value="TsfID"
                        item-text="TsfDescription"
                        :items="ListEstadoFinal">
                    </s-select>
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-text label="Turno" disabled  :placeholder="this.SelectLine.TypeTurnName"></s-text>
                </v-col>
            </v-row>
            <v-row class="s-col-form">
                <v-col>
                    <v-btn  
                        v-if="showbuton1"
                        color="primary"
                        @click="endLineProcess()">
                        Agregar Turno
                    </v-btn> 
                    <v-btn  
                        v-if="!PeriodUpdate"
                        color="primary"
                        @click="startPeriodsProcess()">
                        Iniciar Periodo
                    </v-btn>
                    <v-btn  
                        v-if="PeriodUpdate"
                        color="primary"
                        @click="startPeriodsProcess()">
                        Actualizar Periodo
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="s-col-form">
                <v-col class="pl-0 pt-0">
                    <v-data-table
                        dense
                        :headers="headerPeriods"
                        hide-default-footer
                        :items="itemsDataPeriods"
                        :items-per-page="-1"
                        disable-sort
                        @dblclick:row="rowDoubleClickSend">
                        <template v-slot:item.Actions="{ item }">
                            <v-btn   x-small
                                color="error" 
                                @click="FinalizarPeriodo(item)"
                                v-if="item.PipStatus != 2">
                                FINALIZAR
                            </v-btn>
                        </template>
                        <template v-slot:item.details="{ item }">
                            <v-btn								
								x-small
								:color="'error'"
								fab
								style="margin: 6px 6px 6px 6px;"
								@click="deletePeriodo(item)">
								<v-icon
										style="font-size: 16px !important">
										fas fa-times</v-icon>
							</v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        <v-dialog 
            v-model="dialogTimeDate"
            v-if="dialogTimeDate"
            width="500"
            persistent>
            <v-card>
				<v-container>
					<v-row>
						<s-toolbar
							color="#BDBDBD"
							label="Parametros"
							save
						    @save="saveTimeDate()"
							close
							@close="dialogTimeDate = false"
							dark
						>

						</s-toolbar>
					</v-row>
					<v-row>						
						<v-col>
							<label slot="before" aria-hidden="true" class="v-label theme--light"><b>Hora Cierre Periodo</b></label>
                    		<div class="relojito">
								<datetime
									valueZone="America/Lima"  type="time"
									v-model="PprHour"
									label="Hora">
								</datetime>
							</div>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
        </v-dialog>
    </div>
</template>

<script>
    import _sFrzPackingPeriods from "@/services/FrozenProduction/FrzPackingPeriodsLine.js";
    import _sFrzTypeCutPacking from '@/services/FrozenProduction/FrzTypeCutPackingService.js';
    import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";
    import _typestatefinal from "@/services/FrozenProduction/FrzTypeStateFinal.js";  
    import SSelectBrands from "@/components/FrozenProduction/Chamber/SSelectBrandChamber";

    export default {
        components: {SSelectClient, SSelectBrands},
        props:{
            SelectLine:{
                default: null,
                type:Object,
            }
        },
        data(){
            return{                
                item: {},
                PprHour: 0,
                showbuton1: false,
                headerPeriods: [
                    { text:"item", value :"PipID"},
                    { text: "Cliente", value: "CumNombre"},
                    { text: "Tipo Congelamiento", value:"CecDescription", width:"120"},
                    { text: "Tipo Corte Ac", value: "TypeCutName"},
                    { text: "Tipo Corte Em", value: "TcpDescription"},
                    { text: "Tipo Empaque", value: "TypePackingName"},                  
                    { text: "Cultivo", value: "TypeCultiveName"},
                    { text: "Tipo Cultivo", value: "TypeCropName"},
                    { text: "Periodo", value: "TypePeriodName"},
                    { text: "Hora Inicio", value: "HourBegin"},
                    { text: "Hora Fin", value: "HourEnd"},
                    { text: "Tipo Produccion", value: "TypeProductionName"},
                    { text: "Tipo Variante", value: "TypeVariantName"},
                    { text: "Estado Final", value: "StateFinalName"},
                    { text: "", value: "Actions" ,width:"80"},
                    { text:"Eliminar", value :"details"},
                ],
                itemsDataPeriods:[],
                ConditioningPeriods: [],
                TypesCutPackings: [],
                ListVariante: [],
                ListEstadoFinal: [],
                TypeProduction: 1,
                selectedPeriod: {},
                PeriodUpdate: false,
                CbdID: 0,
                dialogTimeDate: false,
            }
        },
        created(){
            this.PprHour = this.$fun.formatTimeShortView(this.$moment().format("HH:mm"));
        },
        methods:{
            
            deletePeriodo(item)
            {
                this.item = item;
                
                this.$fun.alert("Esta seguro de Eliminar Periodo?", "question").then((r) => { // activar o inactivar linea 
                if (r.value) {
                    this.item.SecStatus = 0;
                    this.item.PipStatus = 0;
                    _sFrzPackingPeriods.save(this.item, this.$fun.getUserID()).then(r => {
                        if(r.status == 200){
                            this.getPeriodsLines();
                            this.$fun.alert("Se dio de baja correctamente", "success");
                        }
                    });
                }
                });
            },
            rowDoubleClickSend(item, event) 
            {               
                this.item = event.item;
                this.selectedPeriod = this.ConditioningPeriods.find(option => option.PchID ==  this.item.PchID);                
                this.PeriodUpdate = true;               
            },
           
            getPeriodsLines(){   
                _sFrzPackingPeriods.list( { PliID: this.SelectLine.PliID }, this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        this.itemsDataPeriods = r.data;   
                    }
                });   
            },
            startPeriodsProcess()
            {
                this.$fun.alert("Esta seguro de Actualizar Periodo?", "question").then((r) => { // activar o inactivar linea 
                if (r.value) {
                    _sFrzPackingPeriods.save(this.item, this.$fun.getUserID()).then(r => {
                        if(r.status == 200){
                            this.getPeriodsLines();
                            this.$fun.alert("Se registro correctamente", "success");
                        }
                    });
                }
                });
            },
            startPeriodsProcess(){
                this.item.PklID = this.SelectLine.PklID;
                this.item.DateBegin = this.PprHour;
                this.item.PliID = this.SelectLine.PliID;
                this.item.UsrCreate = this.$fun.getUserID();
                this.item.TypeCultive = this.SelectLine.TypeCultive;
                this.item.PchID = this.selectedPeriod.PchID;
                this.item.CecID = this.SelectLine.CecID;

                if(this.item.PchID == null){
                    this.$fun.alert("Seleccione Periodo de Acondicionado", "error");
                    return;
                }
                if(this.item.TcpID == undefined || this.item.TcpID == null)
                {
                    this.$fun.alert("Seleccione Tipo de Corte Para Empaque", "error");
                    return;
                }                
                if(this.item.CumID == null){
                    this.$fun.alert("Seleccione Cliente", "error");
                    return;
                }                
                if(this.item.StateFinal == undefined || this.item.StateFinal == null)
                {
                    this.$fun.alert("Seleccione Estado Final", "error");
                    return;
                }

                
                this.$fun.alert("Esta seguro de Iniciar Periodo?", "question").then((r) => { // activar o inactivar linea 
                if (r.value) {
                    _sFrzPackingPeriods.save(this.item, this.$fun.getUserID()).then(r => {
                        if(r.status == 200){
                            this.getPeriodsLines();
                            this.$fun.alert("Se registro correctamente", "success");
                        }
                    });
                }
                });
            },
            FinalizarPeriodo(item){
                this.item = item; 
                this.dialogTimeDate = true;     
                          
            },
            saveTimeDate(){
                
                this.$fun.alert("Esta seguro de Finalizar Periodo?", "question").then((r) => { // activar o inactivar linea 
                if (r.value) {
                    this.item.PipStatus = 2;
                    this.item.UsrUpdate = this.$fun.getUserID();
                    this.item.DateEnd = this.PprHour;
                    console.log(this.item);
                    _sFrzPackingPeriods.save(this.item, this.$fun.getUserID()).then(r => {
                        if(r.status == 200){
                            this.getPeriodsLines();
                            this.$fun.alert("Periodo Finalizado", "success");
                            this.dialogTimeDate = false; 
                            this.item.PipID = 0;
                        }
                    });
                }
                });
            },
            getConditioningPeriods()
            {                
                _sFrzPackingPeriods.getConditioningPeriods(this.SelectLine, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        this.ConditioningPeriods = r.data;
                    }
                });
            },
            ClickTypePeriodoName()
            {
                console.log(this.selectedPeriod );
                if(this.selectedPeriod != undefined)
                {                    
                    this.item.TypeCut = this.selectedPeriod.TypeCut;
                    this.item.TypeCrop = this.selectedPeriod.TypeCrop;
                    if(this.item.TypeCut != null)
                    {
                        this.TypeCutPacking();
                    }
                }
                
            },
            TypeCutPacking()
            {
                _sFrzTypeCutPacking.list(this.item, this.$fun.getUserID()).then( (r) => {
                    if(r.status == 200)
                    {                        
                        this.TypesCutPackings = r.data;
                    }
                })
            },
            changeTypeProduction(item)
            {             
                
                this.TypeProduction = item;
                this.ListVariante = [];
                this.ListEstadoFinal = [];
                this.GetListVariante();
                this.GetListEstadoFinal();
          
            },
            GetListVariante(){
                _typestatefinal.list({DedID : this.TypeProduction, Position : 1}, this.$fun.getUserID()).then( r => {
                    if(r.status == 200)
                    {
                        this.ListVariante = r.data;
                        this.ListVariante.forEach((element, index) => {
                            element.ID = index + 1;
                        });
                       
                    }
                })
            },
            GetListEstadoFinal(){
                _typestatefinal.list({DedID : this.TypeProduction, Position : 2}, this.$fun.getUserID()).then( r => {
                    if(r.status == 200)
                    {
                        this.ListEstadoFinal = r.data;
                        this.ListEstadoFinal.forEach((element, index) => {
                            element.ID = index + 1;
                        });
                        
                    }
                })
            },
        },
        mounted(){   
                 
            this.getPeriodsLines();
            this.getConditioningPeriods();
            
        },
        created(){
            this.PprHour = this.$fun.formatTimeShortView(this.$moment().format("HH:mm"));
        }
    }
</script>

<style>
  .relojito {
      width:auto;
      height: auto;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(160, 159, 159);
      border-radius: 4px;
      padding: 3px;
    }
</style>