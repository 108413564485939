<template>
    <v-card dense >
        <v-card-text>
            <v-row>
                <v-col cols="12">                
                    <s-crud
                        title="Seleccion de materia Prima"
                        ref="scrudbings"           
                        add 
                        :filter="filter"            
                        searchInput   
                        height="auto"                               
                        remove                        
                        :config="config"            
                        @rowSelected="rowSelected($event)"
                        @addEvent="addEvent()"           
                        @save="saveBags($event)"
                        >

                        <template scope="props">                
                            <v-card>
                                <v-row >
                                    <v-col lg="8"  cols=12 style="padding-bottom:0px;">                            
                                        <v-card-text>
                                            <v-container grid-list-xs class="ma-0 pa-0">
                                                <v-row>
                                                    <v-col cols="auto">                                                
                                                        <v-row>
                                                            <v-col hidden>                                                        
                                                                <s-text v-model="PklID">

                                                                </s-text>
                                                                <s-text v-model="PliID">

                                                                </s-text>
                                                            </v-col>  
                                                            <v-col cols="6" >
                                                                <s-select-definition
                                                                    label="Tipo de Envio"
                                                                    v-model="typeArmed"
                                                                    :def="1446"
                                                                    @change="changetypeArmed(typeArmed)">

                                                                </s-select-definition>
                                                            </v-col> 
                                                            <v-col cols="6" >
                                                                <s-text
                                                                    label="Peso Neto"
                                                                    v-model="WeightNet"
                                                                    ref="txtWeightNet"
                                                                    decimal
                                                                ></s-text>
                                                            </v-col>
                                                            <!-- <v-col  cols="4" lg="4" md="6" sm="12"> -->
                                                                <!-- <s-select  
                                                                    label="Cantidad de Kilos de acondicionado"
                                                                    :items="OutputWeihgt"
                                                                    item-value="TraceabilityID"
                                                                    full
                                                                    autocomplete
                                                                    v-model="TraceabilityID"
                                                                    return-object
                                                                    item-text="DescriptionTraceability"
                                                                    :slotTmp="true">
                                                                        <template v-slot:item="data">
                                                                            <v-chip 
                                                                                x-small
                                                                                color="info"
                                                                                style="margin-legt: 5px">
                                                                                {{ "Kg procesado " + data.item.SumAverageWeight }}
                                                                            </v-chip>
                                                                        </template>
                                                                </s-select>                                                             
                                                                </v-col> -->
                                                        </v-row>
                                                        <v-row v-if="typeArmed == 1">
                                                            <v-col cols="6">
                                                                <s-text
                                                                    label="Cantidad de Bolsas"
                                                                    v-model="NumberOfBags"                                                                       
                                                                    ref="txtNumberOfBags"
                                                                    decimal
                                                                    @input="ClickCalcular()"
                                                                ></s-text>
                                                            </v-col>
                                                            <v-col cols="6" >
                                                                <s-text
                                                                    label="Peso prom. bolsa"
                                                                    v-model="WeightAverageBag"
                                                                    ref="txtWeightAverageBag"
                                                                    
                                                                ></s-text>
                                                            </v-col>
                                                            <v-col v-if="PklByp" >
                                                                <s-select-definition
                                                                    label="Tipo Empaque"
                                                                    v-model="TypePacking"
                                                                    return-object
                                                                    :def="1435"
                                                                    @change="UpdateWeightBag(TypePacking)"
                                                                ></s-select-definition>
                                                            </v-col>                                                            
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        <div class="col-md-4" >
                                                            <ul class="city__list">
                                                                <li v-for="(filter, index) in OutputWeihgt" :key="index">
                                                                    <div>
                                                                        <input :id="index"  @input="SelectCheckbox(filter)"  v-model="TraceabilityID" 
                                                                            :value="filter" type="checkbox" :disabled="disabledcheckbox"/>
                                                                            <label class="ml-2" :for="index">{{ filter.TraceabilityID }} 
                                                                                
                                                                            </label>
                                                                            <v-chip 
                                                                                x-small
                                                                                color="info"
                                                                                class="ml-2"
                                                                            >
                                                                                    {{ "Kg procesado " + filter.SumAverageWeight }}
                                                                            </v-chip>
                                                                        
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                    </v-col>                                    
                                </v-row>
                            </v-card>                
                        </template>
                        <template v-slot:filter>
                            <v-container>
                                <v-row justify="center" class="s-col-form">
                                    <v-col>
                                        <s-date 
                                            label="Fecha"
                                            v-model="filter.DateBegin"></s-date>
                                    </v-col>
                                    <v-col>
                                        <s-date 
                                            label="Fecha Fin"
                                            v-model="filter.DateEnd"></s-date>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                        <template v-slot:SecStatus="{row}">
                            <v-icon :color="row.SecStatus == 1 ? 'success': 'default'">mdi-checkbox-blank-circle</v-icon>
                        </template>
                        <template v-slot:Repacking="{ row }">                
                            <v-icon v-if="row.isRepacking == 1 && row.AbbStatus == 1 && row.typeArmed != null" @click="SendBing(row)" color="success">
                                mdi-dropbox
                            </v-icon>                            
                        </template>
                        <template v-slot:Options="{ row }">                
                            <v-icon v-if="row.AbbStatus == 1 && row.isRepacking == 0"  @click="SendBing(row)" color="success">
                                fa-light fa-people-carry
                            </v-icon>                 
                        </template>
                        <template v-slot:PklByp="{row}">
                            <v-switch disabled
                                v-model="row.PklByp">
                            </v-switch>
                        </template>
                    </s-crud>
                </v-col>
            </v-row>            
        </v-card-text>
        <v-dialog v-model="dialogSendBing"
            v-if="dialogSendBing">
            <send-bing :LineSelect="LineSelect" @closeDialog="closedialogSendBing()"></send-bing>
        </v-dialog>       
    </v-card>
</template>
<script>
    import _sAssemblyBigBox from '@/services/FrozenProduction/FrzCtnAssemblyBigBoxService.js'
    import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";
    import _sPackingLines from '@/services/FrozenProduction/FrzPackingLines.js'
    import SendBing from "./FrzPackingSendBing.vue"
    import SSelectDefinition from '../../../../components/Utils/SSelectDefinition.vue';
    // import TypeArmed from "./FrzTypeArmed.vue";
   
    import _scutService from "@/services/FrozenProduction/FrzCtnLinesProcessCutService.js"
    export default
    {
       
        props:{
            SelectLine:{
                default: null,
                type:Object,
            },
            IDPli:{
                default: null,
                type:Number,
            },
            PklByp:{
                default: null,
                type:Boolean,

            }
        },
        components: { SSelectClient, SendBing, SSelectDefinition },
        data() {
            return{
                checkCalcular: true,                
                NumberOfBags: 0, 
                WeightNet: 0,
                
                SumTotalNet: 0,
                props: {} ,          
                itemsDataWeight: [],
                itemsDeleteWeight: [],
                headersDataWeight:[
                    { text: "item", value :"Line", width: 70},   
                    { text: "Bolsa", value: "NameBag", width: 70 },             
                    { text: "Peso", value: "WeightBag", width: 70 },
                    { text: "Eliminar", value: "Actions",  align: "center", width: 50 },
                ],
                WeightBagValue: "",
                disabledcheckbox: false,
                config:{
                    service: _sAssemblyBigBox,                    
                    model:{
                        AbbID: "ID",  
                        SecStatus: "SecStatus",
                        Options: "Options", 
                        AbbStatus: "AbbStatus",   
                        PklByp: "PklByp",             
                        Repacking: "Repacking"     
                    },
                    headers:[
                        {text: "Asignar", value: "Options",  align: "center", width: "10%", sortable: true},
                        {text: "Reempacar", value: "Repacking",  align: "center", width: "10%", sortable: true},
                        {text: "ID", value: "AbbID",  align: "center", width: "10%", sortable: true},
                        {text: "ID Trazabilidad", value: "TraceabilityID",  align: "center", width: "20"},
                        {text: "Tipo", value: "typeArmedName",  align: "center", width: "20"},
                        {text: "Hora Periodo", value: "TypePeriodName",  align: "center", width: "20"},
                        {text: "Tipo Corte Acond.", value: "TypeCutNameAcondition",  align: "center", width: "20"},
                        {text: "Tipo Corte", value: "TypeCutName",  align: "center", width: "20"},
                        {text: "Cliente", value: "CumNombre",  align: "center", width: "80"},
                        {text: "Linea" , value: "LineName" , align: "center", width: "50"},
                        {text: "Tipo de bolsa", value: "TypePackingName",  align: "center", width: "80"},
                        {text: "Cantidad de bolsas", value: "NumberOfBags",  align: "center", width: "30"},
                        {text: "Peso promedio de bolsa", value: "WeightAverageBag",  align: "center", width: "30"},
                        {text: "Peso Neto", value: "WeightNet",  align: "center", width: "100"},
                        {text: "BYP", value: "PklByp",  align: "center", width: "100"},
                        {text: "Estado", value: "SecStatus",  align: "center", width: "30"},

                        
                    ]
                },
                filter:{  
                    PliID: this.IDPli,
                    DateBegin: null,
                    DateEnd: null, 
                },                
                linesPackings: [],
                PklID: 0,
                PliID: 0,
                LineSelect: {},
                dialogSendBing: false,
                typeArmed: 0,
                // ObjTypeBagPacking: {},
                OutputWeihgt: [],
                TraceabilityID: [],
                Periods: {},
                WeightAverageBag: "",
                TypePacking: 0,
                dialogTypeArmed: false,
                itemRepacking:{},
            }
        },
        methods:
        {
            SelectCheckbox(filter){
                
                if(filter.SumAverageWeight > this.WeightNet)
                {
                    this.disabledcheckbox = true;
                }
            },

            GetOutputWeihgt(){
                _scutService.getoutputweihgt({PklID: this.SelectLine.PklID}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200){
                        this.OutputWeihgt = r.data;
                    }
                })
            },

            ClickCalcular()
            {
                this.disabledcheckbox = false;
                this.TraceabilityID = [];
                if(this.checkCalcular)
                {
                    if(this.WeightAverageBag > 0)
                    {
                        this.SumTotalNet = this.WeightAverageBag * this.NumberOfBags;
                        this.WeightNet = this.SumTotalNet;
                    }
                }
            },
            addEvent()
            {                
                this.itemsDataWeight.length = 0;
                this.NumberOfBags = '';
                this.WeightNet = 0;                
                this.SumTotalNet = 0;
                this.WeightAverageBag = this.Periods.WeightPacking;
            },
            addWeightBag() 
            {           
                if(this.WeightBagValue.trim() == "") 
                {
                    this.$refs.focusWeightBagValue.error("Debe ingresar un peso valido");
                    return;
                }
                if(this.WeightBagValue <= 0) 
                {
                    this.$refs.focusWeightBagValue.error("Debe ingresar un peso valido");
                    return;
                }
                let itemsBags = {};
                itemsBags.AbbID = this.AbbID ? this.AbbID : 0;
                itemsBags.NameBag = 'Bolsa ' + parseInt(this.itemsDataWeight.length + 1);
                itemsBags.Line = this.itemsDataWeight.length + 1;
                itemsBags.WeightBag = this.WeightBagValue;
                itemsBags.SecStatus = 1;

                this.itemsDataWeight.push(itemsBags);  

                if(this.checkCalcular == true)
                {
                    this.SumTotalNet = 0;
                    this.itemsDataWeight.forEach((item) => {
                        this.SumTotalNet = parseFloat(this.SumTotalNet) + parseFloat(item.WeightBag);
                    });
                    this.NumberOfBags = this.itemsDataWeight.length;                      
                    this.WeightNet = this.SumTotalNet;
                    let promweight = 0;
                    promweight = parseFloat(this.WeightNet) / parseFloat(this.NumberOfBags);
                    // this.WeightAverageBag = promweight.toFixed(2);
                }                                                                   

                this.WeightBagValue = "";

                this.$refs.focusWeightBagValue.focus();
            
            },
            clickDeleteWeght(item)
            {
                this.itemsDataWeight = this.itemsDataWeight.filter(
                    (x) => {
                        return x.Line != item.Line;
                    }
                )

                
                item.SecStatus = 0;
                this.SumTotalNet = parseFloat(this.SumTotalNet) - parseFloat(item.WeightBag);
                this.WeightNet = this.SumTotalNet;
                if(item.AbbID != undefined)
                {
                    this.itemsDeleteWeight.push(item);                
                }
                this.NumberOfBags = this.itemsDataWeight.length;

                if(this.itemsDataWeight.length == 0){
                    this.WeightNet = 0;                    
                    this.SumTotalNet = 0;
                    this.NumberOfBags = '';
                    
                }
            },
            saveBags(item)
            {                  
                
                if(this.TraceabilityID.length == 0)
                {
                    this.$fun.alert("Seleccione salida de acondicionado", "warning");
                    return;
                }
                item.NumberOfBags = this.NumberOfBags;
                item.WeightNet = this.WeightNet;
                item.WeightAverageBag = this.WeightAverageBag;
                item.UsrCreateID = this.$fun.getUserID();
                item.UsrUpdateID = this.$fun.getUserID();   
                item.typeArmed = this.typeArmed;
                item.PklID = this.PklID;   
                item.PliID = this.PliID; 
                // item.TypePacking = this.Periods.TypePacking;
                item.TrnID = this.SelectLine.TrnID;

                
                let amountlarge = 0;
                let SumWeight = 0;
                this.TraceabilityID.forEach( element => 
                {
                    if(element.SumAverageWeight > amountlarge)
                    {
                        item.TraceabilityID = element.TraceabilityID;   
                        item.TypeCultive = element.TypeCultive;
                        item.TypeCrop = element.TypeCrop; 
                        item.VrtID = element.VrtID;
                        amountlarge = element.SumAverageWeight;
                    }
                    
                    if(element.SumAverageWeight > this.WeightNet)
                    {
                        let amountWeight = 0;
                        this.TraceabilityID.forEach( r => {
                            if(r.SumAverageWeight < element.SumAverageWeight)
                            {
                                if(r.SumAverageWeight < this.WeightNet)
                                {
                                    amountWeight = amountWeight + r.SumAverageWeight;
                                }
                            }
                        });
                        element.WeightNet = this.WeightNet - amountWeight;
                    }else{
                        element.WeightNet = element.SumAverageWeight;
                    }
                    SumWeight = element.SumAverageWeight;
                    
                });
                if(parseFloat(this.WeightNet) > parseFloat(SumWeight))
                {
                    this.$fun.alert("El peso excede a los kilos enviados de acondicionado", "warning");
                    return;
                }       
                item.FrzAssemblyBigBoxDetails = [...this.TraceabilityID];    

                item.PklByp = this.PklByp ? 1 : 0;
                item.CecID = this.SelectLine.CecID;
                if(this.PklByp)
                {
                    item.TypePacking = this.TypePacking.DedValue;
                }
                else 
                {
                    item.TypePacking = this.Periods.TypePacking;
                }
                                                
                item.save();
                this.GetOutputWeihgt();  
                this.TraceabilityID = [];
            },
            rowSelected(item)
            {   
                this.itemsDataWeight = [];
                
                if(item.length > 0 && item[0].AbbID > 0)
                {                      
                                                   
                    this.AbbID = item[0].AbbID; 
                    this.NumberOfBags = item[0].NumberOfBags;
                    this.WeightNet = item[0].WeightNet;
                    this.SumTotalNet = item[0].WeightNet; 
                    this.WeightAverageBag = item[0].WeightAverageBag;
                    _sAssemblyBigBox.getitem(item[0], this.$fun.getUserID())
                    .then((r) => {
                        if(r.status == 200)
                        {                        
                            item[0] = r.data;                            
                            this.itemsDataWeight = item[0].FrzAssemblyBigBoxDetails;
                            this.OrderitemsDataWeight();                   
                        }
                    });
                }
            },
           
            OrderitemsDataWeight()
            {                
                if(this.itemsDataWeight.length > 0)
                {
                    let i = 1;
                    this.itemsDataWeight.forEach((item) => {
                        
                        item.Line = i;
                        item.NameBag = 'Bolsa ' + parseInt(i);
                        i += 1;
                    });                    
                }
            },
            getLinesPackings()
            {
                _sPackingLines.get({}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        this.linesPackings = r.data;
                    }
                });
            },
            SendBing(row){                
                this.dialogSendBing = true;
                this.LineSelect = row;
            },
            clickTypeArmed(row){
                console.log('row',row);              
                this.dialogTypeArmed = true;
                this.itemRepacking = row;
            },
            closedialogSendBing(){
                
                this.dialogSendBing = false;
                this.$refs.scrudbings.refresh();
                this.GetOutputWeihgt();
            },
            closeTypeArmed(){
                this.dialogTypeArmed = false;
                this.$refs.scrudbings.refresh();
                // this.GetOutputWeihgt();
            },
            changetypeArmed(typeArmed){
                this.NumberOfBags = 0;                
                this.ObjTypeBagPacking = {};
            },
            UpdateWeightBag(TypePacking){
                if(TypePacking != 0)
                {
                    this.WeightAverageBag = TypePacking.DedHelper;
                    this.ClickCalcular();
                } 
            }  

        },
        created(){
            this.getLinesPackings();   
            // this.Periods = this.SelectLine.Period;
            // this.WeightAverageBag = this.Periods.WeightPacking; 
                
        },
        mounted(){
            this.Periods = this.SelectLine.Period;
            if(this.Periods == null){
                this.$fun.alert("Debe de tener un periodo activo", "warning");
            }
            this.WeightAverageBag = this.Periods.WeightPacking;
            this.PklID = this.SelectLine.PklID;
            this.PliID = this.SelectLine.PliID;
            this.GetOutputWeihgt();
            console.log(this.PklByp);
        },
        
    }
</script>
<style>
.city__list {
    list-style: none;
    height: 120px;
    width: 280px;
    overflow: auto;
}
</style>
