<template>    
    <v-card>
        <s-toolbar color="primary">
            <v-icon class="mdi-24px mdi-light" @click="close()">mdi-close</v-icon>
        </s-toolbar>
        <v-card-title>
            <v-tabs v-model="currentItem">                        
                <v-tab v-if="LineSelect.typeArmed == 1" href="#Selladoras">Selladoras</v-tab>
                <v-tab v-if="LineSelect.typeArmed == 2" href="#Estacion">Estacion de Trabajo</v-tab>
            </v-tabs>
        </v-card-title>
        <v-card-text>
            <v-tabs-items v-model="currentItem">
                <v-tab-item :value="'Selladoras'">
                    <v-row style="margin:auto" justify="center">
                        <v-col >
                            <s-select 
                                :items="itemsLineProcess"
                                item-value="PlpId"
                                item-text="PloDescription"
                                label="Seleccionar Linea en proceso"                         
                                return-object
                                v-model="objLineOut"                        
                                :slotTmp="true"
                                >
                                
                            </s-select>
                        </v-col>
                        <v-col cols="auto" >
                            <v-btn 
                                class="mt-3"
                                fab
                                x-small                                        
                                depressed color="primary"  
                                @click="SendLineOut()">
                                <v-icon>mdi-send</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row> 
                    <v-row>
                        <v-col lg="12" class="pb-1">
                            <s-crud                      
                                :filter="filter"
                                :config="config"
                                
                                remove
                                @save="save($event)"
                                searchInput
                                
                                title="Listado de bings"
                                no-full
                                height="auto"
                                dense
                                ref="gridPackingAssingBingToLine">
                                <template v-slot:filter>
                                    <v-container>
                                        <v-row justify="center" class="s-col-form">
                                            <v-col>
                                                <s-date 
                                                    label="Fecha"
                                                    v-model="filter.DateBegin"></s-date>
                                            </v-col>
                                            <v-col>
                                                <s-date 
                                                    label="Fecha Fin"
                                                    v-model="filter.DateEnd"></s-date>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </template>
                                <template v-slot:SecStatus="{ row }">
                                    <v-icon :color="row.SecStatus == 1 ? 'success': 'default'">mdi-checkbox-blank-circle</v-icon>
                                </template>
                            </s-crud>
                        </v-col>
                    </v-row>  
                </v-tab-item>
                <v-tab-item :value="'Estacion'">
                    <v-row style="margin:auto" justify="center">
                        <v-col cols="12">                    
                            <v-item-group v-model="selectStation"  multiple active-class="primary">
                                <div class="text-caption mb-2">Estaciones de trabajo</div>
                                    <v-container>
                                        <v-row >
                                            <v-col 
                                                class="fm-card-container"
                                                cols="2"
                                                v-for="item in itemsStatiobWorkProcess"
                                                    v-bind:key="item.PwpID"
                                                    :value="item">
                                                <v-item 
                                                    v-slot="{ active, toggle }"
                                                    >
                                                    
                                                        <v-chip                                         
                                                            :color="active ? 'primary' : ''" @click="toggle"> 
                                                            {{ item.PwsDescription.toLocaleUpperCase() }} 
                                                        </v-chip>
                                                </v-item>  
                                            </v-col>
                                            <v-col align-self="end">
                                                <v-btn 
                                                    
                                                    fab
                                                    x-small                                        
                                                    depressed color="primary"  
                                                    @click="SendStationOut()">
                                                    <v-icon>mdi-send</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>     
                            </v-item-group>
                        </v-col>
                    </v-row> 
                    <v-row>
                        <v-col lg="12" class="pb-1">
                            <s-crud                      
                                :filter="filter"
                                :config="config2"
                                
                                remove
                                @save="save($event)"
                                searchInput
                                
                                title="Listado de bings"
                                no-full
                                height="auto"
                                dense
                                ref="gridPackingAssingBingToTable">
                                <template v-slot:filter>
                                    <v-container>
                                        <v-row justify="center" class="s-col-form">
                                            <v-col>
                                                <s-date 
                                                    label="Fecha"
                                                    v-model="filter.DateBegin"></s-date>
                                            </v-col>
                                            <v-col>
                                                <s-date 
                                                    label="Fecha Fin"
                                                    v-model="filter.DateEnd"></s-date>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </template>
                                <template v-slot:SecStatus="{ row }">
                                    <v-icon :color="row.SecStatus == 1 ? 'success': 'default'">mdi-checkbox-blank-circle</v-icon>
                                </template>
                            </s-crud>
                        </v-col>
                    </v-row>   
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
        <v-card-actions>

        </v-card-actions>
    </v-card>    
</template>

<script>

    import _sAssingBingToLine from "@/services/FrozenProduction/FrzAssingBingToLine.js"
    import _sServiceTableProcess from "@/services/FrozenProduction/FrzPackingStationGroupShift.js"
    import _sAssingBingToTable from "@/services/FrozenProduction/FrzAssingBingToTable.js"
    import _sTurnService from "@/services/FreshProduction/PrfTurnService";
    export default {
        props:{     
            LineSelect:{
                default: null,
                type:Object,
            }
        },
        data(){
            return {
                currentItem: "tab-Funciones",
                itemsLineProcess: [],
                itemsStatiobWorkProcess: [],
                selectStation: [],
                itemTurn: {},
                TrnID: 0,
                objLineOut: {},
                objStationWorkOut: {},
                filter: {
                    DateBegin: null,
                    DateEnd: null,                    
                },
                config:{
                    service: _sAssingBingToLine,
                    model:{
                        AbpID: "ID",
                        DateBegin: "date",
                        DateEnd: "date",
                        SecStatus: "SecStatus"
                    },                
                    headers: [
                        { text: "ID", value: "AbpID", width: 20 },					
                        { text: "Linea en proceso", value: "PloDescription", width: 20 },
                        { text: "N° Bings", value: "AbbID", width: 20 },
                        { text: "Cliente", value: "CumNombre", width: 20 },
                        { text: "Cantidad de bolsas", value: "NumberOfBags", width: 20, align: "center"},
                        { text: "Peso Neto", value: "WeightNet", width: 20, align: "center"},
                        { text: "N° Pallet", value: "SecStatus", width: 20, align: "center"},
                    ],
                },

                
                config2: {
                    service: _sAssingBingToTable,
                    model:{
                        AbpID: "ID",
                        DateBegin: "date",
                        DateEnd: "date",
                        SecStatus: "SecStatus"
                    }, 
                    headers: [
                        { text: "ID", value: "PabID", width: 20 },		
                        { text: "N° Bings", value: "AbbID", width: 20 },
                        { text: "Estacion de Trabajo", value: "PwsDescription", width: 20 },
                        { text: "Cliente", value: "CumNombre", width: 20 },                        
                        { text: "N° Pallet", value: "SecStatus", width: 20, align: "center"},
                    ],
                },
            }
        },
        methods:{
            close(){
                this.$emit("closeDialog");
            },
            initialize(TrnID)
            {
                _sAssingBingToLine.getlinesprocess({TrnID : TrnID}, this.$fun.getUserID())
                .then(r =>{
                    if(r.status == 200)
                    {                        
                        this.itemsLineProcess = r.data;                        
                    }
                });

                
            },
            getStationWorkProcess(TrnID){
                _sServiceTableProcess.list({TrnID : TrnID}, this.$fun.getUserID()).then( r => {
                    if(r.status == 200)
                    {                        
                        this.itemsStatiobWorkProcess = r.data;   
                    }
                })
            },
            TurnActive(){
            let TypeArea = 5
            _sTurnService.turnActive({TypeArea : TypeArea},this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        this.itemTurn = r.data;   
                        this.TrnID = this.itemTurn[0].TrnID;
                     
                        this.initialize(this.TrnID)
                        this.getStationWorkProcess(this.TrnID);                                
                    }
                });      
            },
            SendLineOut(){
                let item = {};                
                item.PlpID = this.objLineOut.PlpID;
                
                if(item.PlpID == undefined)
                {
                    this.$fun.alert("Por Favor Seleccione linea en proceso ", "warning");
					return;
                }
                item.AbbID = this.LineSelect.AbbID;
                item.NumberOfBags = this.LineSelect.NumberOfBags;
                item.TypePacking = this.LineSelect.TypePacking;
                item.typeArmed = this.LineSelect.typeArmed;
                item.TraceabilityID = this.LineSelect.TraceabilityID;
                item.UsrCreateID = this.$fun.getUserID();
				item.UsrUpdateID = this.$fun.getUserID();
                item.TrnID = this.LineSelect.TrnID;
                item.PliID = this.LineSelect.PliID;
                item.TypeCultive = this.LineSelect.TypeCultive;
                item.TypeCrop = this.LineSelect.TypeCrop;
                item.VrtID = this.LineSelect.VrtID;
                item.PklByp = this.LineSelect.PklByp;
                item.CumID = this.LineSelect.CumID;
                item.TypeCut = this.LineSelect.TypeCut;
                item.CecID = this.LineSelect.CecID;
                item.PchID = this.LineSelect.PchID;
                this.$fun.alert("Desea Enviar Binds?", "question").then((r) => {
                    if(r.value)
                    {
                        _sAssingBingToLine.save(item, this.$fun.getUserID())                
                        .then( r => {
                            if(r.status == 200)
                            {
                                this.$refs.gridPackingAssingBingToLine.refresh();
                                this.$fun.alert("Bings ingresado correctamento", "success");
                                this.initialize();
                                this.close();
                            }
                        });
                    }
                });
                
            },
            SendStationOut(){
                let stationWork = []
                this.selectStation.forEach(element => {
                    stationWork.push(this.itemsStatiobWorkProcess[element]);
                });

                let jsonstationWork = []

                stationWork.forEach(element => {
                    jsonstationWork.push(
                        {
                            PwpID : element.PwpID,
                            PwsDescription : element.PwsDescription
                        }
                    );        
                });

                let stringstationWork = {}
                stringstationWork = JSON.stringify(jsonstationWork);
                
                let item = {};
                item.PwpID = jsonstationWork[0].PwpID;
                
                item.jsonPwpID = stringstationWork;
                item.AbbID = this.LineSelect.AbbID;
                item.typeArmed = this.LineSelect.typeArmed;
                item.TraceabilityID = this.LineSelect.TraceabilityID;
                item.UsrCreateID = this.$fun.getUserID();
				item.UsrUpdateID = this.$fun.getUserID();
                item.TypePacking = this.LineSelect.TypePacking;
                item.PliID = this.LineSelect.PliID;
                item.TypeCultive = this.LineSelect.TypeCultive;
                item.TypeCrop = this.LineSelect.TypeCrop;
                item.PklByp = this.LineSelect.PklByp;
                item.VrtID = this.LineSelect.VrtID;
                item.TrnID = this.LineSelect.TrnID;
                item.CumID = this.LineSelect.CumID;
                item.TypeCut = this.LineSelect.TypeCut;
                item.CecID = this.LineSelect.CecID;
                this.$fun.alert("Desea Enviar Binds?", "question").then((r) => {
                    if(r.value)
                    {
                        _sAssingBingToTable.save(item, this.$fun.getUserID())
                        .then( r => {
                            if(r.status == 200)
                            {
                                this.$refs.gridPackingAssingBingToTable.refresh();
                                this.$fun.alert("Bings ingresado correctamento", "success");
                                this.TurnActive();
                                this.close();
                            }
                        });
                    }
                });
            }
        },
        created()
        {
            console.log("LineSelect->", this.LineSelect);
            this.TurnActive();
            // this.initialize();
        }
    }
</script>