<template>
    <div class="col-md-12 col-sm-12">
        <v-row>
            <v-col cols="2">
                <s-select-definition
                    :def="1173"
                    v-model="TypeCultive"
                    label="Cultivo"
                    @input="changeCultive($event)"
                    />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="1" class="mt-5">
                <v-btn v-model="showcard"                    
                    fab
                    x-small
                    color="success"
                    depressed
                    @click="showcard = !showcard"
                    >
                    
                    <v-icon >fas fa-plus</v-icon>
                </v-btn>
            </v-col>
            <v-col v-if="showcard">
                <v-card >
                    <v-card-text>
                        <v-row justify="space-around" no-gutters>
                            <v-col class="ml-1">
                                <s-select
                                    label="Turno de Produccion"
                                    :items="itemTurn"
                                    item-value="TrnID"
                                    item-text="TnrObservation"
                                    v-model="processInitial.TrnID" >

                                </s-select>
                            </v-col>
                            <v-col class="ml-2">
                                <s-select
                                    label="Linea de Entrada"
                                    :items="itemsLines"
                                    item-value="PklID"
                                    item-text="PklDescriptionExit"
                                    v-model="processInitial.PklID" >

                                </s-select>
                            </v-col>
                            <!-- <v-col class="ml-2">
                                <s-select
                                    label="Grupo"
                                    :items="itemsGroup"
                                    item-value="PggID"
                                    item-text="PggNameGroup"
                                    v-model="processInitial.PggID" >

                                </s-select>
                            </v-col> OMITIR POR EL MOMENTO-->
                            <v-col cols="auto" class=" ml-1 mt-2">
                                <v-btn  
                                    color="primary" 
                                    outlined
                                    depressed  
                                    @click="Save()"
                                     >                                     
                                    <v-icon left>mdi-content-save</v-icon>
                                    Agregar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>            
            
            
        </v-row>
        <v-row>
            <v-col>
                <section>
                    <v-container class="container" px-5>                       
                        <v-row  no-gutters v-for="(line, index) in linesInProcess" :key="index">
                            
                            <div  style="width: 5rem;">  
                                <div class="row">
                                    <div class="col">
                                        <!-- <v-btn 
                                            elevation="2"
                                            depressed                                    
                                            fab                                    
                                            bottom
                                            left
                                            @click="showdialogAssemblyBings(line)"
                                            >
                                            <v-icon class="mdi-48px">mdi-cube-outline</v-icon>
                                        </v-btn> -->
                                        <v-btn fab                                    
                                            bottom
                                            left
                                            style="margin-top: 30%;"
                                            @click="dialogBoxSendCamara(line)">
                                            <v-icon class="mdi-48px">mdi-cube-outline</v-icon>
                                        </v-btn>
                                    </div>
                                </div>     
                                <!-- <div class="row">
                                    <div class="col ml-3" > -->
                                        <!-- <v-btn 
                                            x-small
                                            depressed                                    
                                            fab                                    
                                            bottom
                                            left
                                            @click="showdialogAssemblyBingsByP(line)"
                                            >
                                            <v-icon class="mdi-24px">mdi-filter-outline</v-icon>
                                        </v-btn> -->
                                    <!-- </div>
                                </div>          -->

                            </div>
                            <div class="lineBloqued" :class="{ lineBloqued: line.PliState == 2 , lineFree: line.PliState == 1 }">
                                <div style="width: 30rem; height: 5rem;">
                                    <div class="d-flex align-start mb-0 bg-surface-variant">
                                        <div class="ma-1 pa-1 me-auto">
                                            <h3 >{{line.PklDescription}}</h3>
                                        </div>
                                        <div class="ma-0 pa-0">
                                            <v-btn fab
                                                x-small                                        
                                                depressed
                                                @click="ShowDialogOptions(line)">
                                                <v-icon  class="mdi-24px">mdi-dots-vertical-circle</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>


                                    <br>
                                    <div  class="d-flex justify-space-evenly mb-6 bg-surface-variant">    
                                        <!-- <div class="ma-1 pa-2 me-auto">
                                            <v-badge  color="success" >
                                                <template v-slot:badge>
                                                    <span>{{line.numberPerson}}</span>
                                                
                                                </template>
                                                <v-icon  
                                                    class="mdi-24px mdi-dark"
                                                    @click="ShowGroud(line)">mdi-account-check</v-icon>
                                            </v-badge>
                                        </div> -->
                                        <!-- <div class="ma-0 pa-0 me-auto">
                                            <v-btn 
                                                color="warning"
                                                elevation="2"
                                                fab
                                                x-small                                        
                                                depressed
                                                @click="StopLine(line)"
                                                :color="line.PliState == 2 ? 'success' : 'warning'"
                                            >
                                                <v-icon v-if="line.PliState == 1">far fa-pause-circle</v-icon>
                                                <v-icon v-if="line.PliState == 2">far fa-play-circle</v-icon>
                                                
                                            </v-btn>
                                            
                                        </div>                                                                                                                                        -->
                                    </div>                                
                                </div>
                            </div>
                           
                            
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn 
                                    color="#6a73d8"
                                    size="x-large"
                                       
                                    @click="OpenDialogReempaque()"                                 
                                    >
                                    Reempaque
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </section>
            </v-col>
        </v-row>
        <v-dialog v-model="ShowGroupListPerson"
            v-if="ShowGroupListPerson" width="auto">
            <group-lines 
                :SelectLine="SelectLine" 
                @close="UpdateLines()"
                :linesInProcess="linesInProcess"
                :TrnID="TrnID"
                ></group-lines>
        </v-dialog>

        <v-dialog
            v-model="DialogOptions"
            fullscreen
            persistent
            v-if="DialogOptions">
            <v-card>
                <s-toolbar label="DETALLES"
                    dark
                    close
                    @close="DialogOptions = false"
                    color="primary">
                </s-toolbar>
                <v-card outlined >
                    <v-row>
                        <v-col lg="12" cols="12" style="padding-bottom: 10px;">
                            <v-tabs v-model="ItemSelect">
                                <v-tab href="#Periods">Periodos por Linea</v-tab>
                                <v-tab href="#Responsables">Responsables de Linea</v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="ItemSelect">
                                <v-tab-item :value="'Periods'">
                                    <periods-line 
                                        :SelectLine="SelectLine">
                                    </periods-line>
                                </v-tab-item>
                                <v-tab-item :value="'Responsables'">
                                    <line-responsible :SelectLine="SelectLine">

                                    </line-responsible>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-col>
                    </v-row>
                </v-card>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogStopLine"
            v-if="dialogStopLine"
            width="500">
                <line-stock 
                    :SelectLine="SelectLine" 
                    @closeDialogStop="dialogStopLine = false"
                    @refreshlines="refreshlines()"
                >

                </line-stock>
        </v-dialog>
        <v-dialog v-model="dialogAssemblyBings"
            v-if="dialogAssemblyBings"
            fullscreen
            transition="dialog-bottom-transition"
            persistent>
            <assembly-bing :SelectLine="SelectLine" :PklByp="PklByp" @closedialogAssemblyBings="CloseAssemblyBings()">

            </assembly-bing>

        </v-dialog>
        <v-dialog 
            v-model="BoxSendCamara"
            fullscreen
            persistent
            v-if="BoxSendCamara">
            <send-camara :SelectLine="SelectLine" @UpdateLine="LinesInProcess()" @closeBoxSendCamara="closeDialogSendCamara()"></send-camara>
        </v-dialog>

        <v-dialog 
            v-model="dialogReempaque"
            v-if="dialogReempaque"
            fullscreen
            persistent>
            <view-reempaque :itemTurn="itemTurn[0]" @close="dialogReempaque = false"></view-reempaque>
        </v-dialog>
    </div>

</template>

<script>
    import _sTurnService from "@/services/FreshProduction/PrfTurnService";
    import _sPackingLines from '@/services/FrozenProduction/FrzPackingLines.js'
    import _PackingGroup from "@/services/FrozenProduction/FrzPackingGroupService.js"; 
    import _PackingLinesInProcess from "@/services/FrozenProduction/FrzPackingLinesInProcess.js"; 
    import GroupLines from "./GroupLinesInProcess.vue"

    import PeriodsLine from "./FrzPackingPeriodsLine.vue"
    import LineStock from "./FrzPackingLineStock.vue"
    import LineResponsible from "./FrzPackingLineResponsible.vue"
    import SendCamara from "../FrzAssemblyBigBox/FrzArmedBoxSendCamara.vue";

    import AssemblyBing from "../FrzAssemblyBigBox/FrzAssemblyBings.vue";

    import _sPause from "@/services/FrozenProduction/FrzCtnLinesPackingPauseProcess.js"

    import ViewReempaque from "./ViewReempaque.vue";

    export default {
        components:{
            GroupLines, PeriodsLine, LineStock, LineResponsible, AssemblyBing, SendCamara, ViewReempaque
        },
        data(){
            return {
                showcard: false,
                processInitial: {},
                itemturn: [],
                itemsLines: [],
                itemsGroup: [],
                linesInProcess: [],
                ShowGroupListPerson: false,
                SelectLine: { },
                TrnID: 0,
                DialogOptions: false,
                ItemSelect: "tab-Funciones",
                dialogStopLine: false,
                dialogAssemblyBings: false,
                PklByp: false,

                TypeAction: 0,
                lastStop: {},
                TypeCultive: 0,
                BoxSendCamara: false,
                dialogReempaque: false,
            }
        },
        watch:{
            DialogOptions(){
                this.TurnActive();
            }
        },
        mounted(){
            
        },
        created(){
            this.TurnActive();
            this.getLines();
            // this.getGroupSPacking();
            // this.getLastStopByLine();
            
        },        
        methods:{
            closeDialogSendCamara()
            {
                this.BoxSendCamara = false;
                this.LinesInProcess();
            },
            
            OpenDialogReempaque(){
                this.dialogReempaque = true;                
            },
            refreshlines(){
                this.LinesInProcess();
            },
            changeCultive(event){
                this.LinesInProcess();
            },
            TurnActive(){
                let TypeArea = 5
                _sTurnService.turnActive({TypeArea : TypeArea},this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {                        
                        this.itemTurn = r.data;                       
                        this.LinesInProcess();                        
                    }
                });      
            },
            getLines()
            {
                _sPackingLines.getactive({}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        this.itemsLines = r.data; 
                    }
                })
            },
            getGroupSPacking()
            {
                _PackingGroup.list({TypeZoneWork:1}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200){
                        this.itemsGroup = r.data; 
                    }
                });
            },
            Save(){
                if(this.processInitial.TrnID == undefined){
                    this.$fun.alert("Seleccion Turno de produccion", "info");
                    return;
                }
                if(this.processInitial.PklID == undefined){
                    this.$fun.alert("Seleccion Linea de entrada", "info");
                    return;
                }
                // if(this.processInitial.PggID == undefined){
                //     this.$fun.alert("Seleccion grupo", "info");
                //     return;
                // }                
                
                this.processInitial.TypeCultive = this.TypeCultive;
                this.processInitial.UsrID = this.$fun.getUserID();
                _PackingLinesInProcess.save(this.processInitial, this.$fun.getUserID())
                .then(r => {
                    if(r.status == 200){
                        this.$fun.alert("Se registro correctamente", "success");
                        this.LinesInProcess();
                    }
                });
            },
            LinesInProcess() 
            {                     
                if(this.itemTurn != undefined)
                {       
                    
                    _PackingLinesInProcess.list({TrnID: this.itemTurn[0].TrnID, TypeCultive: this.TypeCultive}, this.$fun.getUserID())
                    .then(r => {
                        if(r.status == 200){
                            
                            this.linesInProcess = r.data; 
                        }
                    });
                }
            },
            ShowGroud(line){
                this.ShowGroupListPerson = true;                
                this.SelectLine = line;
                this.TrnID = this.itemTurn[0].TrnID;
            },
            UpdateLines(){
                this.ShowGroupListPerson = false;
                this.LinesInProcess();
            },
            ShowDialogOptions(line){
                this.DialogOptions = true;
                this.SelectLine = line;
            },
            StopLine(line){
                
                if(line.PliState == 1 || line.PliState == 0)
                {
                    this.dialogStopLine = true;
                    this.SelectLine = line;
                }
                else
                {
                    this.savePlay(line.PliID);
                }
            },
            // showdialogAssemblyBings(line)
            // {
            //     this.dialogAssemblyBings = true;
            

            // },
            // showdialogAssemblyBingsByP(line)
            // {

            //     this.dialogAssemblyBings = true;
            
            //     this.PklByp = true;
            // },
            dialogBoxSendCamara(line)
            {                
                if(line.Period == null)
                {
                    this.$fun.alert("No tiene un periodo activo, por favor cree un Periodo", "warning");
                    return;
                }
                this.BoxSendCamara = true;
                this.SelectLine = line;
            },
            CloseAssemblyBings(){                
                this.dialogAssemblyBings = false;
                this.PklByp = false;
            },
            getLastStopByLine(){
  
                _sPause
                .lastPauseline( {}, this.$fun.getUserID())
                .then(resp => {
                    if(resp.status == 200)
                    {
                        if(resp.data != null)
                        {
                            this.TypeAction = resp.data[0].TypeAction;
                            this.lastStop = resp.data;
                            
                        }
                        
                    }
                });
            },
            savePlay(line)
            {

                
                this.$fun.alert("¿Seguro de reanudar?", "question")
                .then(r =>{
                    if(r.value)
                    {
                        // this.lastStop.LpsID = 0;
                        this.lastStop.LpsDate = new Date();
                        this.lastStop.PliID = line;
                        this.lastStop.TypePlace = 1;
                        this.lastStop.TypeAction = 2;
                        this.lastStop.TypeCategory = 0;
                        this.lastStop.CrpID = 0;
                        this.lastStop.RsdID = 0;
                        this.lastStop.SecStatus = 1;
                        this.lastStop.UsrCreateID = this.$fun.getUserID();
                        this.lastStop.UsrUpdateID = this.$fun.getUserID();
                        
                        // return
                        
                        _sPause
                        .save(this.lastStop, this.$fun.getUserID())
                        .then(resp => {
                            if(resp.status == 200)
                            {
                                this.$fun.alert("Reanudado correctamente", "success");
                                this.getLastStopByLine();
                                this.refreshlines()
                                // return;
                            }
                        })
                    }
                })
            },




          
        },
    }
</script>
<style>
    .lineBloqued{
        width:auto; 
        height:auto ;
        border-style: solid;
        background-color: white;
        border-color: red;
        border-width: 1px;
        border-radius: 30px;


        margin: 5px;
        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
        padding: 10px;
    }
    .lineFree{
        width:auto; 
        height:auto ;
        border-style: solid;
        border-color: #eceff1;
        border-width: 1px;
        border-radius: 30px;
        margin:5px 0px 0px 0px;
    }
</style>