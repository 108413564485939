<template>
    <div>
        <v-card dense outlined>
            <v-card-title>
                <h4>Parada de Linea</h4>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col class="s-col-form">
                        <label  aria-hidden="true" class="v-label theme--light"><b>Hora de parada</b></label>
                        <div class="relojito">
                            <datetime valueZone="America/Lima"  type="time" v-model="LpsDate">
                            </datetime>
                        </div>
                    </v-col>
                    <v-col class="s-col-form">
						<s-select-definition
							label="Tipo Parada"
							:def="1432"
							v-model="TypeCategory"
						></s-select-definition>
					</v-col>
                </v-row>
                <v-row>
                    <v-col class="s-col-form">
                        <s-select
							label="Responsable"
							:items="itemResponsible"
							item-text="CrpDescription"
							item-value="CrpID"
							v-model="CrpID"
							clearable
							@input="getReason($event)"
                            ref="txtCrpID"
						></s-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="s-col-form">
                        <s-select
							label="Motivo"
							:items="itemReason"
							item-text="RsdReason"
							item-value="RsdID"
							v-model="RsdID"
							clearable
                            ref="txtRsdID"
						>
						</s-select>
                    </v-col>
                    <v-col class="s-col-form">
						<s-text
							label="N° Personas Indirectas"
							v-model="LpsPersonIndirect"
							clearable
                            ref="txtLpsPersonIndirect"
						>
						</s-text>
					</v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn
					color="success"
					text
					@click="saveStop()"
				>
					Guardar
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn
					color="orange"
					text
					@click="closeDialogStop()"
				>
					Cancelar
				</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>

import _sFrzCtnResponsibleStopService from "@/services/FrozenProduction/FrzCtnResponsibleStopService.js";
import _sPause from "@/services/FrozenProduction/FrzCtnLinesPackingPauseProcess.js"

    export default{
        props:{
            SelectLine: {
                default: null,
                type: Object,
            }
        },
        components:{
        },
        data(){
            return{
                LpsDate: "",
                TypeCategory: "",
                itemResponsible: [],
                CrpID: "",
                RsdID: "",
                LpsPersonIndirect: "",
                itemReason: [],
            }
        },
        created () {            
            this.PprHour = this.$fun.formatTimeShortView(this.$moment().format("HH:mm"));
            this.ListResponsible();
	    },
        methods:{

            closeDialogStop() {
				this.$emit("closeDialogStop");
			},
            ListResponsible(){
                _sFrzCtnResponsibleStopService.listResponsible({},this.$fun.getUserID()) 
                .then( resp => {
                    if(resp.status == 200)
                    {
                        this.itemResponsible = resp.data;                        
                    }
                })   
            },
            getReason(item){
				console.log('motivo de responsable',item);
				if(item > 0){

					let CrpID ={
						CrpID : item
					}
					_sFrzCtnResponsibleStopService.listdetail(CrpID,this.$fun.getUserID()) 
					.then( resp => {
						if(resp.status == 200)
						{
							this.itemReason = resp.data;                        
						}
					})
				}
            },
            saveStop(){
                console.log('Parada de linea Faja de Selección');
                if(this.CrpID == ''){
                    this.$fun.alert('Seleccione responsable de parada','warning');
                    this.$refs.txtCrpID.focus();
                    return;
                }
                if(this.RsdID == ''){
                    this.$fun.alert('Seleccione Motivo de parada','warning');
                    this.$refs.txtRsdID.focus();
                    return;
                }
                if(this.LpsPersonIndirect == ''){
                    this.$fun.alert('Ingrese numero','warning');
                    this.$refs.txtLpsPersonIndirect.focus();
                    return;
                }

                console.log('linea a Pausar')
				this.$fun.alert("¿Seguro de pausar?", "question")
				.then(r =>{
					if(r.value)
					{
                        let item = {
                            PliID : this.SelectLine.PliID,
                            LpsDate : this.LpsDate,
                            TypePlace : 1 , //parada de linea
                            TypeCategory : this.TypeCategory,
                            CrpID : this.CrpID,
                            RsdID : this. RsdID,
                            LpsPersonIndirect : this.LpsPersonIndirect,
                            SecStatus : 1,
                            UsrCreateID : this.$fun.getUserID(),
                            UsrUpdateID : this.$fun.getUserID(),

                        }
                        console.log('this.itemPause',item);
                        // return
						_sPause
						.save(item, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Pausado correctamente", "success");
								this.closeDialogStop();
                                this.$emit("refreshlines");
								return;
							}
						})
					}
				})
            }
            
        }

    }
</script>

<style>
  .relojito {
      width:auto;
      height: auto;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(160, 159, 159);
      border-radius: 4px;
      padding: 3px;
    }
</style>