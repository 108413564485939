import Service from "../Service"

const resource = "assemblybigbox/"

export default {

    save(cmp, requestID){
        return Service.post(resource + "save", cmp,{
            params: {requestID: requestID},
        });
    },
    pagination(dtr, requestID){
        return Service.post(resource + "pagination", dtr,{
            params:{requestID: requestID},
        });
    },
    getitem(fas, requestID) {
        return Service.post(resource + "getitem", fas, {
          params: { requestID: requestID },
        });
    },
}