<template>
    <v-card outlined>        
        <s-toolbar 
            dark
            color="primary"
            save @save="save()"
            close 
            @close="close()">           
            
        </s-toolbar>
        
        <v-card-title>
            <v-row >
                <v-col>
                    <h2>{{ this.SelectLine.PklDescription }}</h2>
                </v-col>
            </v-row>
            
        </v-card-title>    
        <v-divider></v-divider>
        <v-card-subtitle>            
            <v-row justify="center" >
                <v-col >
                    <h2>{{ 'Kilos '}}</h2>
                </v-col>
                <v-col >
                    <h2 style="color: blue;">{{ 'Acondicionado ' + this.SelectLine.Period.FruitProcessed }}</h2>
                </v-col>
                <v-col >
                    <h2 style="color: red;">{{ 'Camara ' + this.FruitProcessedSend }}</h2>
                </v-col>
            </v-row>    
        </v-card-subtitle>
        <v-divider class="text-center"></v-divider>
        <v-card-text>
            <v-row justify="center" class="s-col-form">
                <v-col><h3>{{ 'Cliente: ' + this.SelectLine.Period.CumNombre }}</h3></v-col>
                <v-col><h3>{{ 'Marca: ' + this.SelectLine.Period.CbdBrans }}</h3></v-col>                
                <v-col><h3>{{ 'Cultivo: ' + this.SelectLine.Period.TypeCultiveName }}</h3></v-col>
                <v-col><h3>{{ 'Tipo: ' + this.SelectLine.Period.TypeCropName }}</h3></v-col>
                <v-col><h3>{{ 'Variedad: ' + this.SelectLine.Period.VrtDescription  }}</h3></v-col>
            </v-row >
            <v-row justify="center" class="s-col-form">
                <v-col><h3>{{ 'Turno: ' + this.SelectLine.TypeTurnName }}</h3></v-col>
                <v-col><h3>{{ 'Produccion: ' + this.SelectLine.Period.TypeProductionName  }}</h3></v-col>
                <v-col><h3>{{ 'Variante: ' + this.SelectLine.Period.TypeVariantName  }}</h3></v-col>
                <v-col><h3>{{ 'Estado Final: ' + this.SelectLine.Period.StateFinalName  }}</h3></v-col>
                <v-col><h3>{{ 'Tipo Corte: ' + this.SelectLine.Period.TcpDescription  }}</h3></v-col>
            </v-row>    
            <v-row justify="center" class="s-col-form">
                <v-col><h3>{{ 'Congelamiento: ' + this.SelectLine.Period.CecDescription }}</h3></v-col>
                <v-col><h3>{{ 'Trazabilidad: ' + this.SelectLine.Period.TraceabilityID  }}</h3></v-col>
                <v-col><h3>{{ 'Hora Inicio: ' + this.SelectLine.Period.HourBegin }}</h3></v-col>
                <v-col><h3>{{ 'Periodo: ' + this.SelectLine.Period.TypePeriodName }}</h3></v-col>
                <v-col></v-col>
                <v-col></v-col>
            </v-row>       
        </v-card-text>
        
        <v-card-text>
            <v-divider></v-divider>
            <v-row>
                
            </v-row>
            <v-row justify="center" class="s-col-form">
                <v-col lg="2">
                    <s-select-definition
                        label="Linea"
                        v-model="item.typeLine"
                        :def="1446"
                        >

                    </s-select-definition>
                </v-col>
                <v-col lg="1">
                    <s-text type="number" 
                        v-model="item.TotalBox" 
                        :min="0" 
                        label="N. de Cajas"
                        @input="CalcularBoxWeight()"
                        ></s-text>
                </v-col>
                <v-col lg="2">
                    <s-text 
                        style="font-weight: bold;" disabled                        
                        :value="this.SelectLine.Period.TypePackingName"
                        label="Tipo Presentacion"></s-text> 
                    <!-- <p class="font-weight-bold" style="margin-top: 10%;">{{ this.SelectLine.Period.TypePackingName }}</p> -->
                </v-col> 
                <v-col lg="1">
                    <s-text 
                        style="font-weight: bold;" disabled 
                        :value="this.SelectLine.Period.WeightPacking"
                        label="Peso Bolsa"></s-text> 
                </v-col>
                <v-col lg="1">
                    <s-text 
                        style="font-weight: bold;" disabled 
                        v-model="item.TotalBagWeight"
                        label="Peso Total"></s-text> 
                </v-col>
                <v-col></v-col>
            </v-row>
            <v-row justify="center" class="s-col-form">
                <!-- <v-col lg="3">
                    <s-textarea label="Observacion" v-model="item.Observation"></s-textarea>
                </v-col> -->
                
            <!-- </v-row>
            <v-row justify="center" class="s-col-form"> -->
                <v-col lg="2">
                    <s-select-definition
                        label="Grupo Observacion"
                        v-model="item.TypeGroup"
                        clearable
                        :def="1468"
                        @change="ChangeGrup()"
                        >

                    </s-select-definition>
                </v-col>
                <v-col lg="2" v-if="SubGroups.length > 0">
                    <s-select
                        :items="SubGroups"
                        label="Sub Grupo Observacion"
                        v-model="item.TypeSubGroup"
                        item-value="FsgID"
                        item-text="FsgName"         
                        @input="ChangeGrup()"
                        >

                    </s-select>
                </v-col>
                <v-col lg="2">
                    <s-select
                        label="Tipo de Observacion"
                        v-model="item.TypeObservation"
                        :items="ItemsTypeObservation"
                        item-value="FopID"
                        item-text="TypeObservation"  
                        >

                    </s-select>
                </v-col>
                <v-col>

                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <!-- <v-row class="s-col-form">
                <v-col>
                    <v-btn color="info" @click="save()">
                        <v-icon class="mdi-24px" style="margin-right: 10px;">mdi-content-save</v-icon>Guardar</v-btn>
                </v-col>
            </v-row> -->
        </v-card-actions>
        <v-card-text>
            <v-row class="s-col-form">
                <v-col>
                    <v-data-table
                        dense
                        :headers="HeaderBox"
                        :items="ListBoxes"
                        @click:row="rowClickSend">
                        <template v-slot:item.details="{ item }">
                            <v-btn								
								x-small
								:color="'error'"
								fab
								style="margin: 6px 6px 6px 6px;"
								@click="deleteBox(item)">
								<v-icon
										style="font-size: 16px !important">
										fas fa-times</v-icon>
							</v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
        
    </v-card>
</template>

<script>

import _sFrzPackingArmedBox from "@/services/FrozenProduction/FrzPackingArmedBox.js";
import _sSubGroupService from "@/services/FrozenProduction/SubGroupService.js";
import _sFrzTypeObservation from "@/services/FrozenProduction/FrzTypeObservation.js";
import _PackingLinesInProcess from "@/services/FrozenProduction/FrzPackingLinesInProcess.js"; 
export default{
    props:{
        
        SelectLine:{
                default: null,
                type:Object,
            },
    },
    data(){
        return {
            item:{
                TotalBox : 0                
            },
            HeaderBox:[
                    { text:"item", value :"PaxID"},
                    { text:"Linea", value :"typeLineName" },
                    { text:"Cajas", value :"TotalBox" },
                    { text:"Peso", value :"TotalBagWeight"},
                    { text:"Trazabilidad", value :"TraceabilityID"},
                    { text:"Cliente", value :"CumNombre"},
                    { text:"Marca", value :"CbdBrans"},
                    { text:"Empaque", value :"TypePackingName"},
                    { text:"Tipo Corte", value :"TypeCutName"},
                    { text:"Cultivo", value :"TypeCultiveName"},
                    { text:"Estado Final", value :"StateFinalName"},
                    { text:"Eliminar", value :"details"},
                ],
            ListBoxes: [],
            SelectedItem: {},
            formSubmitted: false,
            FruitProcessed: 0,
            FruitProcessedSend: 0,
            SubGroups: [],
            ItemsTypeObservation: []
        }
    },
    methods:{
        ChangeGrup()
        {
            // 
            _sSubGroupService.list({TypeGroup: this.item.TypeGroup}, this.$fun.getUserID())
            .then( r => {
                if(r.status == 200)
                {                   
                    this.SubGroups = r.data;                    
                }
            });

            _sFrzTypeObservation.list({TypeGroup: this.item.TypeGroup, TypeSubGroup: this.item.TypeSubGroup}, this.$fun.getUserID())
            .then( r => {
                if(r.status == 200)
                {                   
                    this.ItemsTypeObservation = r.data;
                }
            });
        },
        
        close(){
            this.$emit("closeBoxSendCamara");
        },
        CalcularBoxWeight(){
            this.item.TotalBagWeight    = (this.SelectLine.Period.WeightPacking * this.SelectLine.Period.QuantityBag) * this.item.TotalBox;
            this.item.TotalBags         = parseFloat(this.SelectLine.Period.QuantityBag * this.item.TotalBox);

        },


        save()
        {
            
            if(this.SubGroups.length > 0)
            {
                if(this.item.TypeSubGroup == undefined)
                {
                    this.$fun.alert("Seleccione Sub Grupo", "error");
                    return;
                }
            }

            if(this.item.TypeGroup != undefined && this.item.TypeObservation == undefined)
            {
                this.$fun.alert("Seleccione tipo de Observacion", "error");
                return;
            }
            if(this.FruitProcessed <= this.FruitProcessedSend + this.item.TotalBagWeight)
            {
                this.$fun.alert("Error el peso a enviar sobrepasa el peso de fruta recibida de Acondicionado", "error");
                return;
            }
            if(this.formSubmitted) return;
            this.formSubmitted = true;
            this.item.TypeVariant = this.SelectLine.Period.TypeVariant;
            this.item.TypeProduction = this.SelectLine.Period.TypeProduction;
            this.item.StateFinal = this.SelectLine.Period.StateFinal;
            
            this.item.TypeGroup = this.item.TypeGroup;
            this.item.TypeSubGroup = this.item.TypeSubGroup;
            this.item.TypeObservation = this.item.TypeObservation;
            this.item.TotalBox = parseFloat(this.item.TotalBox);
            this.item.CumID = this.SelectLine.Period.CumID;
            this.item.CbdID = this.SelectLine.Period.CbdID;
            this.item.BoxBag = parseFloat(this.SelectLine.Period.QuantityBag);
            this.item.BagWeight = this.SelectLine.Period.WeightPacking;
            this.item.TypePacking = this.SelectLine.Period.TypePacking;
            this.item.TypeCultive = this.SelectLine.Period.TypeCultive;
            this.item.CecID = this.SelectLine.Period.CecID;
            this.item.VrtID = this.SelectLine.Period.VrtID;
            this.item.PipID = this.SelectLine.Period.PipID;
            this.item.bagutilizadas = this.item.TotalBags;
            this.item.TraceabilityID = this.SelectLine.Period.TraceabilityID;
            this.item.SecStatus = 1;
            this.item.PxdStatus = 1;

            // INSERTAREMOS EL TIPO CORTE EMPAQUE AL TIPO CORTE
            this.item.TypeCut =  this.SelectLine.Period.TcpID;

            let listitem = [];
            let headitem = {};
            headitem.TotalBox = parseFloat(this.item.TotalBox);
            headitem.TotalBagWeight = parseFloat(this.item.TotalBagWeight);
            headitem.CumID = this.item.CumID;
            headitem.PlpID = 0;
            headitem.PliID = this.SelectLine.PliID;
            headitem.TotalBags = this.item.TotalBags;
            headitem.PipID = this.SelectLine.Period.PipID;
            
            
            headitem.SecStatus = 1;
            headitem.PaxStatus = 1;
            if(this.SelectedItem.PaxID > 0)
            {
                headitem.PaxID = this.SelectedItem.PaxID;
                this.item.PxdID = this.SelectedItem.boxDetails[0].PxdID;                
            }
            listitem.push(this.item);
            headitem.boxDetails = listitem;
            if(this.item.TotalBox == 0)
            {
                this.$fun.alert("Agregue un numero de cajas", "error");
                return;
            }
            
            this.$fun.alert("Desea realizar el registro?", "question").then((r) => { // activar o inactivar linea 
                if (r.value) {
                    _sFrzPackingArmedBox.save(headitem, this.$fun.getUserID())
                    .then( r => {
                        if(r.status == 200)
                        {
                            this.$emit("UpdateLine");
                            this.formSubmitted = false;
                            this.item.TotalBox = 0;
                            this.item.TypeGroup = '';
                            this.item.TypeObservation = '';    
                            this.item.TotalBagWeight = 0;                      
                            this.$fun.alert("Se registro correctamente", "success");
                            this.listperiods(); 
                            this.UpdateFruitProcessedSend();
                        }
                    });
                }
            });
        },
        listperiods(){
            this.SelectLine.PipID = this.SelectLine.Period.PipID;
            _sFrzPackingArmedBox.listperiods(this.SelectLine, this.$fun.getUserID())
                .then( r => {
                    if(r.status == 200)
                    {
                        //ESTÁ DE ESTA MANERA POR QUE AL PRINCIPIO DE DESARROLLO DE OTRA FORMA CON DETALLE Y CABECERA
                       this.ListBoxes = r.data;
                       this.ListBoxes.forEach( element => {
                            element.TraceabilityID = element.boxDetails[0].TraceabilityID;
                            element.TypeCutName = element.boxDetails[0].TypeCutName;
                            element.TypePackingName = element.boxDetails[0].TypePackingName;
                            element.StateFinalName = element.boxDetails[0].StateFinalName;
                            element.TypeCultiveName = element.boxDetails[0].TypeCultiveName;
                            element.typeLineName = element.boxDetails[0].typeLineName;
                            element.CbdBrans = element.boxDetails[0].CbdBrans;
                       })
                    }
                });
        },
        deleteBox(item){
            
            item.SecStatus  = 0;
            item.PaxStatus = 0;
            item.boxDetails[0].SecStatus = 0;
            item.boxDetails[0].PxdStatus = 0;
            
            this.$fun.alert("Esta seguro de eliminar el registro?", "question").then((r) => { // activar o inactivar linea 
                if (r.value) {
                    _sFrzPackingArmedBox.save(item, this.$fun.getUserID())
                    .then( r => {
                        if(r.status == 200)
                        {
                            this.$fun.alert("Se elimino correctamente", "success");
                            this.listperiods(); 
                        }
                    });
                }
            });
        },
        rowClickSend(row){            
            this.SelectedItem = row;        
            this.item.TotalBox = this.SelectedItem.TotalBox;            
        },
        UpdateFruitProcessedSend()
        {
            console.log("", this.SelectLine);
            _PackingLinesInProcess.list({TrnID: this.SelectLine.TrnID, TypeCultive: this.SelectLine.TypeCultive}, this.$fun.getUserID())
            .then(r => {
                if(r.status == 200)
                {             
                    const ProccessChamber =  r.data;  
                    this.FruitProcessedSend = ProccessChamber[0].Period.FruitProcessedSend;
                }
            });
        }
    },
    mounted(){
        this.listperiods();    
        this.FruitProcessed = this.SelectLine.Period.FruitProcessed;
        this.FruitProcessedSend = this.SelectLine.Period.FruitProcessedSend;
    }
}
</script>