<template >      
    <v-card outlined >
        <s-toolbar 
            color="primary">           
            <v-icon class="mdi-24px mdi-light" @click="close()">mdi-close</v-icon>
        </s-toolbar>
        <v-card-title>           
            <v-tabs v-model="currentItem">      
                <v-tab href="#EntryRetail">Seleccion</v-tab>
            </v-tabs>
        </v-card-title>
        <v-card-text>
            <v-tabs-items v-model="currentItem">
                <v-tab-item :value="'EntryRetail'">
                    <entry-retail :SelectLine="SelectLine" :PklByp="PklByp" :IDPli="PliID" @close="close()" />
                </v-tab-item>                            
            </v-tabs-items>
        </v-card-text>                  
    </v-card>            
</template>
<script>

    import EntryRetail from './FrzAssemblyBingsRetail.vue'
    // import EntryRepacking from './FrzAssemblyBingsRepacking.vue'
    // import EntryBings from './FrzAssemblyBing.vue'
    export default {
        props:{
            SelectLine:{
                default: null,
                type:Object,
            },
            PklByp:{
                default: null,
                type:Boolean,
            }
        },
        components: {EntryRetail},//, EntryRepacking},
        data() {
            return {
                currentItem: "tab-Funciones",
                PliID: this.SelectLine != null ? this.SelectLine.PliID : 0,
            }
        },
        methods:{
            close(){
                this.$emit("closedialogAssemblyBings");
            }
        },
        mounted(){
            
        },
        created(){
            console.log("SelectLine", this.SelectLine);
        }
    }
</script>