<template>
    <div>
        <v-container>
            <br>
            <v-row class="pt-3 pr-3 pl-3">
                <h4>Asignar responsable de la Linea</h4>
                <v-spacer></v-spacer>
                <v-btn rounded fab x-small @click="addItemGP()">
                    <i class="fas fa-plus"></i>
                </v-btn>   
            </v-row>
            <v-divider></v-divider><br>
            <v-row>
                <v-col cols="3" lg="3" md="3" sm="12">		
                    <s-toolbar-person
                        v-model="line.LprDocumentNumber"
                        @returnPerson="hadReturnPerson($event)"
                        ref="focusPrsDocument"
                        noCamera
                        noEdit
                    />					
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="12">
                    <s-text
                        label="Nombres"
                        v-model.trim="newPerson.PprName"
                        ref="focusPgdName"
                    ></s-text>
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="12">
                    <s-text
                        label="Apellidos"
                        v-model.trim="newPerson.PprLastName"
                    ></s-text>
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="12" hidden>
                    <s-text
                        label="DNI"
                        v-model.trim="newPerson.LprDocumentNumber"
                    ></s-text>
                </v-col>
            </v-row>
            <v-row class="pt-3 pr-3 pl-3">
                <v-col class="pl-0 pt-0">
                    <v-data-table
                        dense
                        :headers="headerResponsable"
                        :items="itemsDataPG"
                        hide-default-footer
                        :items-per-page="-1"
                        disable-sort
                    >
                        <template v-slot:item.Actions="{ item }">
                            <v-btn
                                @click="clickDeleteItemGP(item)"
                                x-small
                                fab
                                color="error"
                            >
                            <v-icon style="font-size: 16px !important"
                            >mdi-close</v-icon
                            >
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row>
            <v-col>
                <v-btn  
                    color="primary"
                    @click="saveResponsables()">
                    Guardar
                </v-btn> 
            </v-col>
        </v-row>
        </v-container>
    </div>
</template>

<script>
    import SToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
    import _sFrzPackingLineResponsible from "@/services/FrozenProduction/FrzPackingLineResponsible.js"

    export default{
        components:{SToolbarPerson},
        props:{
            SelectLine:{ 
                default: null,
                type:Object,
            }
        },
        data(){
            return {
                headerResponsable: [
                { text:"item", value :"Line"},
                { text: "Nombre", value: "PprName"},
                { text: "Apellidos", value: "PprLastName"},
                { text: "dni", value: "LprDocumentNumber"},
                { text: "", value: "Actions" },
                ],
                itemsDataPG: [],
                newPerson: {},
                line:{},
                itemsDataPGAdd: [],
                itemsDeletePG: [],
            }
        }, 
        methods: {
            addItemGP(){
                let existPerson = false;
                this.itemsDataPG.forEach(element => {
                    if(element.LprDocumentNumber == this.newPerson.LprDocumentNumber){
                        existPerson = true;                    
                    }
                });
                if (existPerson){
                    this.$fun.alert("Trabajador ya Ingresado", "warning");
                    return ;
                }
                if (!this.newPerson.PprName) {
                    this.$refs.focusPgdName.error("Debe ingresar el nombre de un Responsable");
                    return;
                } 
                else 
                {
                    this.newPerson.Line = this.itemsDataPG.length + 1;
                    this.newPerson.SecStatus = 1;
                    this.itemsDataPG.push({...this.newPerson});               
                    this.newPerson = {};                 
                    this.$refs.focusPgdName.focus();             
                }
            },
            hadReturnPerson(line){
           
                if (line != null) {
                    this.newPerson.PprName = line.NtpName.trim();
                    this.newPerson.PprLastName = line.NtpPaternalSurname.trim() +" "+ line.NtpMaternalSurname.trim();
                    this.newPerson.LprDocumentNumber = line.PrsDocumentNumber;
                    

                    this.newPerson.PliID = this.SelectLine.PliID;
                    this.newPerson.UsrCreateID = this.$fun.getUserID();

                    this.newPerson.PrsID = line.PrsID;
                    this.newPerson.NtpID = line.NtpID;
                }
                
            },
            clickDeleteItemGP(item) {
                this.itemsDataPG = this.itemsDataPG.filter(
                    (x) => { return x.Line != item.Line }
                );
                item.SecStatus = 0;
                if(item.PprID > 0)
                {
                    this.itemsDeletePG.push({ ...item} );                                    
                }
                
            },
            saveResponsables() 
            {                
                if(this.itemsDataPG.length <= 0 && this.itemsDeletePG.length <= 0){
                    this.$fun.alert("Grupo debe tener Integrantes", "warning");
                    this.$refs.focusPrsDocument.focus();
                    return;
                }               

                this.itemsDataPGAdd = [...this.itemsDataPG, ...this.itemsDeletePG];
                
                this.itemsDataPGAdd.forEach(element => {
                
                    element.UsrCreateID = this.$fun.getUserID();
                    element.UsrUpdateID = this.$fun.getUserID();
                    
                });

                this.$fun.alert("Estas seguro de guardar?", "question").then((r) => {
                    if(r.value){

                        _sFrzPackingLineResponsible.saveResponsablesLine(this.itemsDataPGAdd, this.$fun.getUserID()).then((r) => {
                        if (r.status == 200) {
                            this.$fun.alert("Registrado correctamente", "success");
                            
                            this.itemsDataPG = []
                            this.listResponsables();
                            }
                        });
                    }
                })
            },

            listResponsables(){
            
                let obj ={
                    PliID : this.SelectLine.PliID,
                }
                
                _sFrzPackingLineResponsible.listdetail(obj, this.$fun.getUserID())
                .then((r) => {
                    if (r.status == 200) {
                        r.data.forEach((el, index) => {
                            el.Line = index + 1;
                        });                       

                        this.itemsDataPG = r.data;
                    }
                    
                })
            },
        },        
        created () {
            this.listResponsables();
        },
    }
</script>