import Service from "../Service";

const resource = "lineproccessresponsible/";

export default {

    saveResponsablesLine(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },
    listdetail(obj, requestID) {
        return Service.post(resource + "listdetail", obj, {
            params: { requestID: requestID },
        });
    },

}